import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import themes from '@bitcoin-portal/bitcoincom-pkg-theme';
import { GlobalStyle } from './styled';
import SEO from './SEO';

export const LocationContext = React.createContext();
const { light } = themes;

const Layout = ({ children, location }) => (
  <React.Fragment>
    <ThemeProvider theme={light}>
      <SEO />
      <GlobalStyle />
      <LocationContext.Provider value={location}>
        <React.Fragment>{children}</React.Fragment>
      </LocationContext.Provider>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Layout;
