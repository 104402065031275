import { createGlobalStyle } from 'styled-components';
import { theme } from '@bitcoin-portal/bitcoincom-storybook';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  ___gatsby,
  ___gatsby > div {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
    margin: 0;
    padding: 0;
    color: ${theme.palette.text.primary};
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: ${colors.solid.bgGrey};
    @media screen and (min-width: ${theme.breakpoints.md}px) {
      font-size: 20px;
    }
  }

  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
  }

  a {
    display: inline-block;
    color: ${theme.palette.text.link};
    letter-spacing: 0;
    font-weight: 600;
    text-decoration: none;
  }

  button.osano-cm-widget {
    display: none;
  }

  .osano-cm-dialog--type_box.osano-cm-dialog--position_bottom-right {
    bottom: 0;
    right: 0;
    max-width: unset;
    width: 100%;
    background: #FFFFFF;
    ${media.md`
      bottom: 1em;
      right: 1em;
      max-width: 20em;
      width: calc(100vw - 2em);
      background: rgba(255,255,255,0.7);
    `}
  }
`;
